import React, { PureComponent } from 'react';

type State = {};

type Props = {
  [key: string]: string | number | Function;
};

export default class extends PureComponent<Props, State> {
  render = (): React.ReactElement => (
    <svg
      width="190"
      height="36"
      viewBox="0 0 190 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...this.props}>
      <path
        d="M51.456 35.48C48.896 35.48 46.944 34.76 45.6 33.32C44.256 31.848 43.584 29.752 43.584 27.032V9.368C43.584 6.648 44.256 4.568 45.6 3.128C46.944 1.656 48.896 0.919998 51.456 0.919998C54.016 0.919998 55.968 1.656 57.312 3.128C58.656 4.568 59.328 6.648 59.328 9.368V12.248H54.336V9.032C54.336 6.824 53.424 5.72 51.6 5.72C49.776 5.72 48.864 6.824 48.864 9.032V27.416C48.864 29.592 49.776 30.68 51.6 30.68C53.424 30.68 54.336 29.592 54.336 27.416V20.84H51.696V16.04H59.328V27.032C59.328 29.752 58.656 31.848 57.312 33.32C55.968 34.76 54.016 35.48 51.456 35.48ZM71.31 35.48C68.718 35.48 66.734 34.744 65.358 33.272C63.982 31.8 63.294 29.72 63.294 27.032V9.368C63.294 6.68 63.982 4.6 65.358 3.128C66.734 1.656 68.718 0.919998 71.31 0.919998C73.902 0.919998 75.886 1.656 77.262 3.128C78.638 4.6 79.326 6.68 79.326 9.368V27.032C79.326 29.72 78.638 31.8 77.262 33.272C75.886 34.744 73.902 35.48 71.31 35.48ZM71.31 30.68C73.134 30.68 74.046 29.576 74.046 27.368V9.032C74.046 6.824 73.134 5.72 71.31 5.72C69.486 5.72 68.574 6.824 68.574 9.032V27.368C68.574 29.576 69.486 30.68 71.31 30.68ZM83.8567 1.4H91.9207C94.5447 1.4 96.5127 2.104 97.8247 3.512C99.1367 4.92 99.7927 6.984 99.7927 9.704V26.696C99.7927 29.416 99.1367 31.48 97.8247 32.888C96.5127 34.296 94.5447 35 91.9207 35H83.8567V1.4ZM91.8247 30.2C92.6887 30.2 93.3447 29.944 93.7927 29.432C94.2727 28.92 94.5127 28.088 94.5127 26.936V9.464C94.5127 8.312 94.2727 7.48 93.7927 6.968C93.3447 6.456 92.6887 6.2 91.8247 6.2H89.1367V30.2H91.8247Z"
        fill="black"
      />
      <path
        d="M110.096 6.2H104.576V1.4H120.896V6.2H115.376V35H110.096V6.2ZM124.412 1.4H132.236C134.956 1.4 136.94 2.04 138.188 3.32C139.436 4.568 140.06 6.504 140.06 9.128V11.192C140.06 14.68 138.908 16.888 136.604 17.816V17.912C137.884 18.296 138.78 19.08 139.292 20.264C139.836 21.448 140.108 23.032 140.108 25.016V30.92C140.108 31.88 140.14 32.664 140.204 33.272C140.268 33.848 140.428 34.424 140.684 35H135.308C135.116 34.456 134.988 33.944 134.924 33.464C134.86 32.984 134.828 32.12 134.828 30.872V24.728C134.828 23.192 134.572 22.12 134.06 21.512C133.58 20.904 132.732 20.6 131.516 20.6H129.692V35H124.412V1.4ZM131.612 15.8C132.668 15.8 133.452 15.528 133.964 14.984C134.508 14.44 134.78 13.528 134.78 12.248V9.656C134.78 8.44 134.556 7.56 134.108 7.016C133.692 6.472 133.02 6.2 132.092 6.2H129.692V15.8H131.612ZM144.732 1.4H150.012V35H144.732V1.4ZM154.926 1.4H162.894C165.614 1.4 167.598 2.04 168.846 3.32C170.094 4.568 170.718 6.504 170.718 9.128V10.472C170.718 12.2 170.43 13.608 169.854 14.696C169.31 15.784 168.462 16.568 167.31 17.048V17.144C169.934 18.04 171.246 20.376 171.246 24.152V27.032C171.246 29.624 170.558 31.608 169.182 32.984C167.838 34.328 165.854 35 163.23 35H154.926V1.4ZM162.27 15.08C163.326 15.08 164.11 14.808 164.622 14.264C165.166 13.72 165.438 12.808 165.438 11.528V9.656C165.438 8.44 165.214 7.56 164.766 7.016C164.35 6.472 163.678 6.2 162.75 6.2H160.206V15.08H162.27ZM163.23 30.2C164.158 30.2 164.846 29.96 165.294 29.48C165.742 28.968 165.966 28.104 165.966 26.888V23.96C165.966 22.424 165.694 21.368 165.15 20.792C164.638 20.184 163.774 19.88 162.558 19.88H160.206V30.2H163.23ZM175.292 1.4H189.692V6.2H180.572V15.08H187.82V19.88H180.572V30.2H189.692V35H175.292V1.4Z"
        fill="#ABABAB"
      />
      <rect y="1" width="34" height="34" fill="#196AC9" />
      <path
        d="M8.55556 22.75L6 8.3125L13.0278 14.875L17.5 7L21.9722 14.875L29 8.3125L26.4444 22.75H8.55556ZM26.4444 26.6875C26.4444 27.475 25.9333 28 25.1667 28H9.83333C9.06667 28 8.55556 27.475 8.55556 26.6875V25.375H26.4444V26.6875Z"
        fill="white"
      />
    </svg>
  );
}
