import React, { PureComponent } from 'react';

type State = {};

type Props = {
  [key: string]: string | number | Function | object;
};

export default class extends PureComponent<Props, State> {
  render = (): React.ReactElement => (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...this.props}>
      <path
        d="M36.0181 34.1343L25.957 24.0732C28.3747 21.1707 29.5804 17.4478 29.3231 13.679C29.0659 9.91016 27.3655 6.38562 24.5758 3.83855C21.7861 1.29147 18.1218 -0.0820198 14.3452 0.00379178C10.5686 0.0896034 6.97042 1.62811 4.29927 4.29927C1.62811 6.97042 0.0896034 10.5686 0.00379178 14.3452C-0.0820198 18.1218 1.29147 21.7861 3.83855 24.5758C6.38562 27.3655 9.91016 29.0659 13.679 29.3231C17.4478 29.5804 21.1707 28.3747 24.0732 25.957L34.1343 36.0181L36.0181 34.1343ZM2.71212 14.7023C2.71212 12.3308 3.41533 10.0127 4.73282 8.04089C6.05031 6.06912 7.92292 4.53232 10.1138 3.62481C12.3047 2.71731 14.7156 2.47986 17.0414 2.9425C19.3673 3.40515 21.5037 4.5471 23.1806 6.22395C24.8574 7.9008 25.9994 10.0372 26.462 12.3631C26.9247 14.689 26.6872 17.0998 25.7797 19.2907C24.8722 21.4816 23.3354 23.3542 21.3636 24.6717C19.3919 25.9892 17.0737 26.6924 14.7023 26.6924C11.5234 26.6889 8.47567 25.4245 6.22784 23.1767C3.98002 20.9289 2.71564 17.8812 2.71212 14.7023Z"
        fill="black"
      />
    </svg>
  );
}
