import React, { PureComponent } from 'react';

type State = {};

type Props = {
  [key: string]: string | number | Object | Function;
};

export default class extends PureComponent<Props, State> {
  render = (): React.ReactElement => (
    <svg
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...this.props}>
      <path
        d="M14.3733 10.1232C14.3733 10.8372 14.2543 11.5245 14.0366 12.165L13.0062 11.1346C13.0736 10.8017 13.1075 10.4629 13.1075 10.1232V8.85738C13.1075 8.68952 13.1741 8.52854 13.2928 8.40984C13.4115 8.29114 13.5725 8.22446 13.7404 8.22446C13.9082 8.22446 14.0692 8.29114 14.1879 8.40984C14.3066 8.52854 14.3733 8.68952 14.3733 8.85738V10.1232ZM8.04407 15.1866C9.07954 15.1866 10.0416 14.8765 10.8441 14.3423L11.753 15.2524C10.8494 15.9077 9.78778 16.311 8.677 16.4208V18.9842H12.4745C12.6424 18.9842 12.8034 19.0508 12.9221 19.1695C13.0408 19.2882 13.1075 19.4492 13.1075 19.6171C13.1075 19.7849 13.0408 19.9459 12.9221 20.0646C12.8034 20.1833 12.6424 20.25 12.4745 20.25H3.61361C3.44575 20.25 3.28477 20.1833 3.16607 20.0646C3.04737 19.9459 2.98069 19.7849 2.98069 19.6171C2.98069 19.4492 3.04737 19.2882 3.16607 19.1695C3.28477 19.0508 3.44575 18.9842 3.61361 18.9842H7.41115V16.4208C5.84996 16.2639 4.40271 15.5327 3.35016 14.3691C2.29762 13.2054 1.71482 11.6923 1.71484 10.1232V8.85738C1.71484 8.68952 1.78153 8.52854 1.90022 8.40984C2.01892 8.29114 2.17991 8.22446 2.34777 8.22446C2.51563 8.22446 2.67661 8.29114 2.79531 8.40984C2.91401 8.52854 2.98069 8.68952 2.98069 8.85738V10.1232C2.98069 11.4661 3.51415 12.754 4.46372 13.7036C5.41329 14.6532 6.70118 15.1866 8.04407 15.1866ZM11.8416 3.794V9.97006L10.5758 8.70422V3.794C10.5787 3.13356 10.3234 2.49812 9.8644 2.02325C9.40538 1.54838 8.77897 1.27169 8.11881 1.2522C7.45865 1.23271 6.817 1.47197 6.33077 1.91893C5.84454 2.36589 5.55222 2.98516 5.51618 3.64463L4.44654 2.57499C4.73635 1.72338 5.31896 1.00225 6.09066 0.539973C6.86237 0.0776982 7.77308 -0.0957201 8.66067 0.0505911C9.54826 0.196902 10.3551 0.653448 10.9376 1.33895C11.5201 2.02446 11.8405 2.89443 11.8416 3.794Z"
        fill="black"
      />
      <path
        d="M9.92423 13.4233L8.97738 12.4765C8.59335 12.6289 8.17783 12.6851 7.76711 12.64C7.35638 12.595 6.96292 12.4501 6.62108 12.218C6.27923 11.9859 5.99939 11.6736 5.80598 11.3085C5.61257 10.9434 5.51147 10.5364 5.51149 10.1232V9.01183L4.24565 7.74598V10.1232C4.24545 10.7882 4.41987 11.4416 4.75145 12.018C5.08303 12.5944 5.56015 13.0736 6.13509 13.4077C6.71003 13.7419 7.36262 13.9191 8.02758 13.9219C8.69255 13.9246 9.34657 13.7527 9.92423 13.4233ZM0 1.71043L15.1902 16.9006L16.0864 16.0044L0.896219 0.814209L0 1.71043Z"
        fill="black"
      />
    </svg>
  );
}
