import React, { PureComponent } from 'react';

type State = {};

type Props = {
  [key: string]: string | number | Function | object;
};

export default class extends PureComponent<Props, State> {
  render = (): React.ReactElement => (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...this.props}>
      <rect width="50" height="50" fill="#196AC9" />
      <path
        d="M40.2481 29.5588L33.2004 25.0002L40.2481 20.4417C41.0085 19.9498 41.2289 18.928 40.7395 18.1639L37.7105 13.4348C37.4073 12.9613 36.8913 12.6786 36.3303 12.6786C36.0157 12.6786 35.7092 12.7693 35.4439 12.9409L29.4384 16.8255V9.64807C29.4383 8.73943 28.7026 8 27.7983 8H22.2017C21.2974 8 20.5617 8.73943 20.5617 9.64807V16.8253L14.5562 12.9408C14.291 12.7691 13.9843 12.6784 13.6699 12.6784C13.1089 12.6784 12.5929 12.9611 12.2898 13.4346L9.26053 18.1638C8.77107 18.9279 8.99154 19.9497 9.75193 20.4415L16.7996 25L9.75193 29.5586C8.99154 30.0505 8.77107 31.0723 9.26053 31.8364L12.2895 36.5654C12.5928 37.0389 13.1087 37.3217 13.6697 37.3217C13.9843 37.3217 14.2908 37.231 14.5561 37.0594L20.5616 33.1748V40.3519C20.5616 41.2607 21.2974 42 22.2016 42H27.7981C28.7025 42 29.4381 41.2606 29.4381 40.3519V33.1748L35.4436 37.0594C35.709 37.231 36.0154 37.3217 36.33 37.3217H36.3302C36.8909 37.3217 37.4069 37.0391 37.7102 36.5654L40.7392 31.8364C41.2288 31.0724 41.0083 30.0507 40.2481 29.5588Z"
        fill="white"
      />
    </svg>
  );
}
