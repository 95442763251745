import React, { PureComponent } from 'react';

type State = {};

type Props = {
  [key: string]: string | number | Function;
};

export default class extends PureComponent<Props, State> {
  render = (): React.ReactElement => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000">
      <g>
        <rect fill="none" height="24" width="24" />
        <rect fill="none" height="24" width="24" />
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g />
        <g>
          <path d="M12,14c1.66,0,3-1.34,3-3V5c0-1.66-1.34-3-3-3S9,3.34,9,5v6C9,12.66,10.34,14,12,14z" />
          <path d="M17,11c0,2.76-2.24,5-5,5s-5-2.24-5-5H5c0,3.53,2.61,6.43,6,6.92V21h2v-3.08c3.39-0.49,6-3.39,6-6.92H17z" />
        </g>
      </g>
    </svg>
  );
}
