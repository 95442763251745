import React from 'react';
import { NewActiveTemplate } from '../../templates/NewActiveTemplate';

const NewActivePage = (): React.ReactElement => (
  // useEffect(() => {

  // }, []);

  <NewActiveTemplate />
);
export default NewActivePage;
